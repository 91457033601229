import {
  minValueMessage,
  notANumberValueMessage,
} from "@/composables/validationMessages";
import { FormItemRule } from "naive-ui";

const baseCostNotNegativeNumberFieldRules = (field: string): FormItemRule => {
  return {
    validator(_rule: FormItemRule, value: string): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        if (isNaN(+value)) {
          reject(Error(notANumberValueMessage(field)));
        } else if (+value < 0) {
          reject(Error(minValueMessage(field, 0)));
        } else {
          resolve();
        }
      });
    },
    trigger: ["input", "blur"],
  };
};

export default baseCostNotNegativeNumberFieldRules;
